import React, { Component } from 'react';
import CoinsComponent from './coins';
import CompanyComponent from './compnay';
import ExchangeComponent from './exchange';
import GetInTouch from './getInTouch';

import './style.scss'

class FooterComponent extends Component {

    render() {

        return (
            <div className="container-fluid border-line">
                <div className="container footer">
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <ExchangeComponent />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <CoinsComponent />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <CompanyComponent />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <GetInTouch />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterComponent;