import React, { Component } from 'react';
import LogoComponent from '../../header/logo/logoComponent';

import './style.scss'

class CoinsComponent extends Component {

    render() {

        return (
            <ul className="get-in-touch">
                <li>
                    <h4 className="footer-title">Market Coins</h4>
                </li>
                <li className="footer-menu-item">
                    <a href="#">Bitcoin</a>
                </li>
                <li className="footer-menu-item">
                    <a href="#">Ethereum</a>
                </li>
                <li className="footer-menu-item">
                    <a href="#">Litecoin</a>
                </li>
                <li className="footer-menu-item">
                    <a href="#">Cardano</a>
                </li>
                <li className="footer-menu-item">
                    <a href="#">Polkadot</a>
                </li>
                <li className="footer-menu-item">
                    <a href="#">Bitcoin Cash</a>
                </li>
            </ul>
        );
    }
}

export default CoinsComponent;







