import React, { Component } from 'react';
import './style.scss';

class AboutContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="container c-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2 className="color-primary text-bold">For Those</h2>
                            <h2 className="color-primary text-bold">Who Prefer</h2>
                            <h2 className="text-bold">Simple Finance</h2>
                            <p className="pb-2">At some point in our lives, we will all need to meet digital assets face-to-face. We believe it’s inevitable. What should not be inevitable is feeling frustrated by the complexity of the crypto-puzzle.</p>
                            <p>Developing bibiTrade, we aimed to create one platform to manage your assets. Whatever was difficult to understand and use, we tried to make it plain and simple.</p>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="m-bibi-01"></div>
                        </div>
                    </div>
                </div>


                <div className="container c-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="m-bibi-02"></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2 className="color-primary text-bold">For Those</h2>
                            <h2 className="color-primary text-bold">Who Like to Stay</h2>
                            <h2 className="text-bold">One Step Ahead</h2>
                            <p className="pb-2">From day trader to data scientist - bibiTrade lets you create, test, and monetize any kind of trading algorithm. Access trusted strategies with no technical knowledge, or provide algorithms for other users.</p>
                            <p>bibiTrade offers a shortcut — all steps can be done inside one platform. It allows our users to react fast and be the first to move when markets do.</p>

                        </div>
                    </div>
                </div>


                <div className="container c-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2 className="color-primary text-bold">For Those</h2>
                            <h2 className="color-primary text-bold">Who Want All Their </h2>
                            <h2 className="text-bold">Tools in One Place</h2>
                            <p className="pb-2">bibiTrade Interest Account is the place to store idle assets. Use it if you want your crypto to work when you sleep.</p>
                            <p>Crypto Exchange is a convenient place to buy, sell, or swap coins on the go. bibiTrade accepts fiat deposits with Visa/MasterCard, so you can easily buy Bitcoin, Ethereum, Monero, etc. in a couple of minutes.</p>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="m-bibi-03"></div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default AboutContentComponent;