import React, { Component } from 'react';
import HeaderComponent from '../../header/headerComponent';
import SmallBox from './smallBox/smallBoxComponent';


import './style.scss'

class WelcomeComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            percent: 0
        }
    }

    render() {

        return (
            <div className="relative w1-bibitrade welcome-bg-color" >
                <div className="container">
                    <div className="welcome-text ">
                        <h2>Ready to be the next</h2>
                        <h1 className="color-primary">Winner Trader on Market</h1>
                        <div className="main__text-container">
                            <p>
                                bibiTrade helps you swap and grow your assets. <br />
                                We combine the best of traditional and novel finance to offer you both solid security and favorable terms.
                            </p>
                        </div>

                        <div className="w-mobile-app">

                            <a href="http://accounts.bibitrade.com/register" className="btn-sign-up" >
                                <p>Open Account Free</p>
                                <i className="fa fa-chevron-right"></i>
                            </a>
                            <div className="mobile-icon bg-primary">
                                <i className="fab fa-apple"></i>
                            </div>
                            <div className="mobile-icon bg-primary">
                                <i className="fab fa-google-play"></i>
                            </div>

                        </div>
                    </div>
                </div>
                <div >
                    <div className="xxx">
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1599 250" class="main-section__image main-section__image_xl"><path fill-rule="evenodd" clip-rule="evenodd" d="M1599 250V0s-282.24 163.026-817.835 88.8125C245.573 14.5977 0 250 0 250h1599z" fill="#F8F8F8"></path></svg>
                    </div>
                    <SmallBox />
                </div>


            </div>
        );
    }
}

export default WelcomeComponent;