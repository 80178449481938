import React, { Component, useEffect } from 'react';
import LogoComponent from './logo/logoComponent';
import MenuComponent from './menu/menuComponent';
import './style.scss'
 

const HeaderComponent=() => {
    const [scrolled,setScrolled]=React.useState(false);
  const handleScroll=() => {
      const offset=window.scrollY;
      if(offset > 500 ){
        setScrolled(true);
      }
      else{
        setScrolled(false);
      }
    }
  
    useEffect(() => {
      window.addEventListener('scroll',handleScroll)
    })
  let navbarClasses=['navbar'];
    if(scrolled){
      navbarClasses.push('scrolled');
    }
  
     return (
        <header className={navbarClasses.join(" ")}>
          <div className="container">
          <div className="logo">
            <LogoComponent />
        </div>
        <nav className="navigation">
            <MenuComponent />
        </nav>
          </div>
    </header>
     )
  };
  export default HeaderComponent;