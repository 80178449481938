import React, { Component } from 'react';
import LogoComponent from '../../header/logo/logoComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faCog } from '@fortawesome/free-solid-svg-icons'
import './style.scss'

class GetInTouch extends Component {

    render() {

        return (
            <ul className="get-in-touch">
                <li>
                    <h4 className="footer-title">Get in touch</h4>
                </li>
                <li>hi@bibiTrade.com</li>
                {/* <li className="pt-1">Kyiv, Ukraine</li> */}
                <li className="social-network">
                    <a href="https://www.facebook.com/BibiTrade-101270155197797" target="_blank" className="social-icon icon-facebook"></a>
                    <a href="https://twitter.com/bibiTradeApp" target="_blank" className="social-icon icon-twitter"></a>
                    <a href="https://www.instagram.com/bibitrade/" target="_blank" className="social-icon icon-instagram"></a>
                    <a href="https://www.linkedin.com/company/bibitrade" target="_blank" className="social-icon icon-linkedin"></a>
                </li>
                <li className="social-network">
                    <LogoComponent />
                </li>
                <li className="bibiTrade">© 2021 bibiTrade Technologies Inc.</li>
            </ul>
        );
    }
}

export default GetInTouch;