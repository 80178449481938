import React, { Component } from 'react';
import './style.scss'

class AffiliateContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        return (
            <div className="container-fluid">

                <div className="container">
                    <div className="career-text">
                        <h1><span>bibiTrade</span> Affiliate Market</h1>
                    </div>
                </div>

                <div className="container c-container">
                    <div className="row py-5">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2 className="text-bold">Join</h2>
                            <h2 className="color-primary text-bold">The Affiliate </h2>
                            <h2 className="text-bold">Program</h2>
                            <p className="pb-4">Increase your revenue by becoming a bibiTrade partner. invite your friends and earn from every transaction they make.</p>
                            <a href="http://accounts.bibitrade.com/register" className="btn btn-light" >
                                <p>Join the Program</p>
                                <i className="fa fa-chevron-right"></i>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="a-bibi-01"></div>
                        </div>
                    </div>


                    <div className="row py-5">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="a-bibi-02"></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2 className="color-primary text-bold">From Each</h2>
                            <h2 className="text-bold">Friend's Deal</h2>
                            <p className="pb-2">You will get 3% of every payment amount in months of every Interest Account deposit.</p>
                        </div>

                    </div>


                    <div className="row py-5">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2 className="color-primary text-bold">How to the</h2>
                            <h2 className="text-bold pb-3">Invite Friends</h2>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 pb-4">
                                    <p className="pb-2 text-bold">Web-Sites and Blogs</p>
                                    <p>You can use our affiliate link on your website to get more bonuses.</p>
                                </div>
                                <div className="col-lg-6 col-md-12 pb-4">
                                    <p className="pb-2 text-bold">Video Review</p>
                                    <p>You can place our link in the description under your video review & share it.</p>
                                </div>
                                <br />
                                <div className="col-lg-6 col-md-12 pb-4">
                                    <p className="pb-2 text-bold">Social Media</p>
                                    <p>You can share our affiliate link on your social media account.</p>
                                </div>
                                <div className="col-lg-6 col-md-12 pb-4">
                                    <p className="pb-2 text-bold">QR-Codes</p>
                                    <p>You can use our link in your QR-codes to share the link faster.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="a-bibi-03"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AffiliateContentComponent;