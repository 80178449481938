import React, { Component } from 'react';
 import HeadUpComponent from '../../component/common/headUp/headUpComponent';
import FooterComponent from '../../component/footer/menu/footerComponent';
import HeaderComponent from '../../component/header/headerComponent';
import PrivacyContentComponent from '../../component/privacy/privacyContent/privacyContent';
import './style.scss'

class PrivacyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        return (
            <div className="container-fluid p-0 m-0">
                <HeaderComponent />
                <HeadUpComponent />
                <PrivacyContentComponent />
                <FooterComponent />
            </div>
        );
    }
}

export default PrivacyComponent;