import React, { Component } from 'react';
import LogoComponent from '../../header/logo/logoComponent';

import './style.scss'

class ExchangeComponent extends Component {

    render() {

        return (
            <ul className="get-in-touch">
                <li>
                    <h4 className="footer-title">Exchange</h4>
                </li>
                <li className="footer-menu-item">
                    <a href="https://binance.com">Binance</a>
                </li> 
                <li className="footer-menu-item">
                    <a href="https://pro.coinbase.com">Coinbase Pro</a>
                </li> 
                <li className="footer-menu-item">
                    <a href="https://www.huobi.com/">Huobi Global</a>
                </li> 
                <li className="footer-menu-item">
                    <a href="https://www.kraken.com">Kraken</a>
                </li> 
                <li className="footer-menu-item">
                    <a href="https://www.kucoin.com">KuCoin</a>
                </li> 
            </ul>
        );
    }
}

export default ExchangeComponent;







