import React, { Component } from 'react';

import './style.scss'

class ReadyToGetStartedComponent extends Component {

    render() {

        return (
            <div className="container-fluid ready-to-join world  h-700  d-flex justify-content-center align-items-center" >

                <div className="container d-flex flex-column justify-content-center align-items-center ">
                    <h1 className="title-page txt-center pb-3">Ready to get started? <br /> Join us right now!</h1>
                    <a href="http://accounts.bibitrade.com/register" className="btn-sign-up ">
                        <p>Get Started</p>
                    </a>
                </div>
            </div>
        );
    }
}

export default ReadyToGetStartedComponent;