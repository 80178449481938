import React, { Component } from 'react';
import './style.scss';

class InvestingCryptoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid f8f8f8 c-container">
                 <div className="bottom-vector"></div>
                <div className="container">
                    <div className="row pt-5 d-flex flex-lg-row flex-sm-column-reverse">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="investing-image">
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h1 className="color-primary text-bold">Investing on Crypto</h1>
                            <h1 className="text-bold">Build Your Future</h1>
                            <ul className="earn-ul">
                                <li>
                                    <i class="fas fa-check-circle color-primary"></i>
                                    <span>We understand crypto investing so you don't have to.</span>
                                </li>
                                <li>
                                    <i class="fas fa-check-circle color-primary"></i>
                                    <span>We grow your assets 15% monthly risk-free.</span>
                                </li>
                                <li>
                                    <i class="fas fa-check-circle color-primary"></i>
                                    <span>Our innovative dashboard design provides you with a clear overview of your portfolio and all crypto trades.</span>
                                </li>
                            </ul>
                            <a href="http://accounts.bibitrade.com/register" className="btn btn-light" >
                                <p>Open Account Free</p>
                                <i className="fa fa-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
               
            </div>

        );
    }
}

export default InvestingCryptoComponent;