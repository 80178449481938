import React, { Component } from 'react';
import CareerItemComponent from '../careersItem/careerItemComponent';
import './style.scss';

class CareersContent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid">

                <div className="container">
                    <div className="career-text">
                        <h1>Explore <span>Opportunities</span></h1>
                        <p>Join us! Let’s build the next big thing together.</p>
                    </div>

                    <div>
                        <h3 className="p-3"><b>Marketing</b></h3>
                        <hr />
                        <CareerItemComponent position="Senior Marketing Executive" location="Remote, Full Time" category="Marketing" />
                        <CareerItemComponent position="Institutional Sales Manager" location="Remote, Full Time" category="Marketing" />
                        <CareerItemComponent position="Email Marketing Specialist" location="Remote, Full Time" category="Marketing" />
                        <CareerItemComponent position="SMM Specialist" location="Remote, Full Time" category="Marketing" />
                        <CareerItemComponent position="Head Of Content" location="Remote, Full Time" category="Marketing" />
                        <h3 className="p-3"><b>Engineering</b></h3>
                        <hr />
                        <CareerItemComponent position="Senior Backend Engineer (Python/Go/Rust/php)" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="Senior Security Engineer" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="QA Automation Engineer" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="Mobile App Developer (React Native)" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="DevOps Engineer" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="Frontend Engineer (React)" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="Product Manager" location="Remote, Full Time" category="Engineering" />
                        <CareerItemComponent position="Security Architect" location="Remote, Full Time" category="Engineering" />
                        <h3 className="p-3"><b>Finance</b></h3>
                        <hr />

                        <CareerItemComponent position="Crypto Asset Analyst" location="Remote, Full Time" category="Finance" />
                        <CareerItemComponent position="Trading Analyst" location="Remote, Full Time" category="Finance" />
                        <CareerItemComponent position="Data Analyst" location="Remote, Full Time" category="Finance" />
                        <CareerItemComponent position="Financial Analyst" location="Remote, Full Time" category="Finance" />
                    </div>
                </div>
            </div>
        );
    }
}

export default CareersContent;