import React, { Component } from 'react';

import './style.scss'

class HowToGetStartedComponent extends Component {

    render() {

        return (
            <div className="container-fluid  h-700 pt-5 shape-03 light-bg" >
                <div className="container">
                    <div className="text-container">
                        <h1 className="title-page">How to Get Started</h1>
                        <p>bibiTrade is quick to Register and it’s easy to get started.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12 p-2">
                            <div className="how-get-it-worked-item">
                                <div className="how-get-it-worked-icon icon-api"></div>
                                <h3>Connect Exchange</h3>
                                <p>Connect bibiTrade to your exchange. API keys are securely encrypted<br /> (HMAC SHA-256).</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 p-2">
                            <div className="how-get-it-worked-item">
                                <div className="how-get-it-worked-icon icon-setup"></div>
                                <h3>setup your budget</h3>
                                <p>Create your portfolio and set up your budget based on advanced metrics or implement an existing strategy.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 p-2">
                            <div className="how-get-it-worked-item">
                                <div className="how-get-it-worked-icon icon-auto-rebalanced"></div>
                                <h3>Auto Rebalanced</h3>
                                <p>bibiTrade keeps your portfolio on track by automatically executing portfolio rebalanced.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default HowToGetStartedComponent;