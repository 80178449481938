import React, { Component } from 'react';

import './style.scss'

class HowItWorksComponent extends Component {

    render() {

        return (
            <div className="  h-700 f8f8f8" >
         

            </div>
        );
    }
}

export default HowItWorksComponent;