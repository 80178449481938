import React, { Component } from 'react';
import './style.scss';

class CareerItemComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: this.props.position,
            location: this.props.location,
            category: this.props.category,
        }
    }
    render() {
        return (
            <div className="career-item-container">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <p className="career-category">{this.state.category}</p>
                        <h2>{this.state.position}</h2>
                        <p className="career-location">
                        <i class="fas fa-map-marker-alt"></i>
                            <span>{this.state.location}</span>
                        </p>
                    </div>
                    <div className="col-lg-2 col-sm-12 align-items-center d-flex">
                        <div className="apply-button">
                            <p>Apply Now</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CareerItemComponent;