

import React, { Component } from 'react';

import './style.scss'

class LogoComponent extends Component {

    render() {

        return (
           <a href="/" className="logo-container">
                <div className="logo-beta m-0"></div>
           </a>
        );
    }
}

export default LogoComponent;


