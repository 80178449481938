import React, { Component } from 'react';

import './style.scss'

class FeaturesOfTheBotComponent extends Component {

    render() {

        return (
            <div className="container-fluid c-container" >
                <div className="vector06"></div>
                <div className="container">
                    <div className="text-container">
                        <h1 className="title-page">Features of the Bot</h1>
                        <p>with bibiTrade never miss any fluctuation.</p>
                    </div>
                    <div className="row pt-5 d-flex flex-lg-row flex-sm-column-reverse">
                        <div className="col-lg-6 col-sm-12  pt-5">
                            <div className="mock-container">
                                <div className="mock-02"></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12  pt-5 flex-column d-flex justify-content-center">
                            <div className="p-5">
                                <h1 className="text-bold color-primary">Make Money</h1>
                                <h1 className="text-bold">While You Sleep</h1>
                                <p>24/7 completely automate trading.	Free yourself from concentrating on the volatile market!</p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row pt-5 pb-5 ">
                        <div className="col-lg-6 col-sm-12  pt-5 flex-column d-flex justify-content-center">
                            <div className="p-5">
                                <h1 className="text-bold color-primary">Buy the Dip</h1>
                                <h1 className="text-bold">Non-Stop</h1>
                                <p>Decrease your average buying price dramatically in a sudden price drop.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12  pt-5">
                            <div className="mock-container">
                                <div className="mock-02"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturesOfTheBotComponent;