import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import LogoComponent from '../../header/logo/logoComponent';

import './style.scss'

class CompanyComponent extends Component {



    navigateTo(link) {
        browserHistory.push(link);
    }


    render() {

        return (
            <ul className="get-in-touch">
                <li>
                    <h4 className="footer-title">Company</h4>
                </li>
                <li className="footer-menu-item" >
                    <a href="/pricing">Pricing</a>
                </li>
                <li className="footer-menu-item">
                    <a href="/about-us">About Us</a>
                </li>
                <li className="footer-menu-item">
                    <a href="/contact-us">Contact Us</a>
                </li>
                <li className="footer-menu-item">
                    <a href="careers">Careers</a>
                </li>
                <li className="footer-menu-item">
                    <a href="blog.bibitrade.com">Blog</a>
                </li>
                <li className="footer-menu-item">
                    <a href="affiliate">Affiliate Program</a>
                </li>
                {/* <li className="footer-menu-item">
                    <a href="terms-of-service">Terms of Service</a>
                </li> */}
                <li className="footer-menu-item">
                    <a href="privacy-policy">Privacy Policy</a>
                </li>

            </ul>
        );
    }
}

export default CompanyComponent;







