import React, { Component } from 'react'; 

import './style.scss'

class DashboardComponent extends Component {

    render() {

        return (
            <div className="p-3">
                <div> 
                    <h1>dasdfasdasdasdasdsadasd</h1> 
                </div>

                <div className="row pt-5">

        
                    <div className="col-lg-6 col-md-12">

                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardComponent;