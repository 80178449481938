import React, { Component } from 'react';
import './style.scss';

class PrivacyContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid">

                <div className="container">
                    <div className="career-text">
                        <h1><span>bibiTrade</span> Privacy Policy</h1>
                    </div>

                    <div className="col-lg-6 col-sm-12 privacy-container pb-5">
                        <h3>1. Introduction</h3>
                        <p>
                            bibiTrade needs to collect and use certain types of information about the physical and legal persons that wish to participate in the bibiTrade Platform managed by bibiTrade.
                        </p>
                        <p>

                            In accordance with the provisions of GDPR, any User who provides his information under the present policies confirms that he understands and accepts the reason for the gathering of information and consents to the processing of information for the purposes of money laundering prevention.
                        </p>
                        <p>
                            Any User is entitled to know who is responsible for the processing of his or hers personal data.
                        </p>
                        <p>
                            Any data collected during the work of the Platform whether is collected on paper, stored in a computer database, or recorded on other material is subject to this policy and is protected under the applicable law and the GDPR provisions regarding data control and processing.
                        </p>

                        <h3>2. Data Protection Officer</h3>
                        <p>
                            bibiTrade and its officers and employees shall be collectively referred to as the Data Protection Officer under this policy and in accordance with the relative provisions of the GDPR, which means that it determines what purposes personal information held will be used for. It is also responsible for cooperating with the state regulatory organs regarding the correct application of the state legislation, and the correct use and disclosure of information.
                        </p>
                        <p>
                            The Data Protection Officer and bibiTrade in accordance with GDPR take upon themselves the following obligations:
                        </p>

                        <ul>
                            <li>Implement measures to ensure compliance with GDPR;
                            </li>
                            <li>Implement the necessary security measures to protect the rights of the data subjects when gathering and processing data;
                            </li>
                            <li>Conduct data protection impact assessments of high-risk processing activities;
                            </li>
                            <li>Implement privacy by default design;
                            </li>
                            <li>Implement the valid data breach notification.
                            </li>
                        </ul>
                        <h3>3. Disclosure</h3>
                        <p>
                            bibiTrade may share data with the state regulatory organs and other authorities when that is required by the applicable law or the provisions of the AML policy.
                        </p>
                        <p>
                            The Platform user will be made aware in most circumstances how and with whom their information will be shared.
                        </p>
                        <p>
                            Every Platform user shall agree with this policy and shall consent to his data being used in accordance with the provisions of this policy and the AML policy regarding analysis of data and disclosure of data.
                        </p>
                        <p>
                            There are circumstances where the law allows bibiTrade to disclose data (including sensitive data) without the data subject’s consent.
                        </p>
                        <p>
                            These are:
                        </p>
                        <ul>
                            <li>Carrying out a legal duty or as authorized by the Financial Inspection of Ukraine or any other competent legal authority.
                            </li>
                            <li>Protecting vital interests of any party, including the Platform user.
                            </li>
                            <li>The information was already made public by other third parties.
                            </li>
                            <li>For the conducting of any legal proceedings, obtaining legal advice, or defending any legal rights.
                            </li>
                            <li>Disclosing data to state authorities under the AML policy in order to avoid or prevent money laundering.
                            </li>
                        </ul>
                        <p>
                            Any User shall have the right to obtain from the Data Protection Officer the erasure of personal data concerning him or her without undue delay and the Data Protection Officer shall have the obligation to erase personal data without undue delay where one of the following grounds applies:
                        </p>
                        <ul>
                            <li>the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;
                            </li>
                            <li>the data subject withdraws consent on which the processing is based (such a withdrawal constitutes that the User understands that the company will be entitled to terminate all cooperation with such a User);
                            </li>
                            <li>the personal data have been unlawfully processed;
                            </li>
                            <li>the personal data have to be erased for compliance with a legal obligation in European Union or Member State law to which the Data Protection Officer is subject;
                            </li>
                        </ul>
                        <p>In case of any queries or questions in relation to this policy, please contact the bibiTrade Data Protection Officer at <a href="dpo@bibitrade.com" className="original_link">dpo@bibitrade.com</a></p>

                    </div>

                </div>
            </div>
        );
    }
}

export default PrivacyContentComponent;