import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import './style.scss'
import 'swiper/swiper.scss';

class SmallBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      percent: 0
    }
  }

  render() {
    const slide1 = (
      <li className="card__filled white-color-text">
        <span className="swiper-icon">
          <i className="fas fa-chart-line"></i>
        </span>
        <h3 className="card__label text-medium-size">Earn More</h3>
        <span className="card__description text-normal text-medium">with zero deposit and
          withdrawal fees</span>
      </li>
    );
    const slide2 = (
      <li className="card__filled white-color-text">
        <span className="swiper-icon">
          <i class="fab fa-bitcoin"></i>
        </span>
        <h3 className="card__label text-medium-size">Keep Money Safe</h3>
        <span className="card__description text-normal text-medium">at the certified custodian,
          covered by insurance</span>
      </li>
    );
    const slide3 = (
      <li className="card__filled white-color-text">
        <span className="swiper-icon">
          <i className="fas fa-headset"></i>
        </span>
        <h3 className="card__label text-medium-size">Get Support Fast</h3>
        <span className="card__description text-normal text-medium">we experts standing
          by ready to help</span>
      </li>
    );
    const slide4 = (
      <li className="card__filled white-color-text">
        <span className="swiper-icon">
          <i class="fas fa-sync-alt"></i>
        </span>
        <h3 className="card__label text-medium-size">Stay Flexible</h3>
        <span className="card__description text-normal text-medium">your money is never frozen</span>
      </li>

    );
    const slide5 = (
      <li className="card__filled white-color-text">
        <span className="swiper-icon">
          <i className="fas fa-shield-alt"></i>
        </span>
        <h3 className="card__label text-medium-size">Trust for a Reason</h3>
        <span className="card__description text-normal text-medium">we're a licensed and
          regulated company</span>
      </li>
    );

    return (
      <div className="container">
        <div className="swiper-mobile">
          <Swiper
            modules={[Pagination, Scrollbar]}
            spaceBetween={30}
            slidesPerView={3} 
            breakpoints={{
              200: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              } 
            }}
            
          >
            <SwiperSlide>
              {slide1}
            </SwiperSlide>
            <SwiperSlide>
              {slide2}
            </SwiperSlide>
            <SwiperSlide>
              {slide3}
            </SwiperSlide>
            <SwiperSlide>
              {slide4}
            </SwiperSlide>
            <SwiperSlide>
              {slide5}
            </SwiperSlide>
          </Swiper>
        </div>
        <ul className="swiper-desktop card">
          {slide1}
          {slide2}
          {slide3}
          {slide4}
          {slide5}
        </ul>
      </div>
    );
  }
}

export default SmallBox;