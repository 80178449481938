import React, { Component } from 'react';
import './style.scss';



class HeadUpComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid headUp-container">
                <div className="container headUp-text">
                    <h1>We Are <span>bibiTrade</span></h1>
                    <p>a professional crypto auto trading platform, known on the market since 2020.</p>
                </div>
                <div className="headUp-shape"></div>
            </div>

        );
    }
}

export default HeadUpComponent;