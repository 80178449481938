import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';


import RootComponent from './root';
import DashboardComponent from './container/dashboard/dashboard';
import IndexComponent from './container/index';
import AboutComponent from './container/about/aboutUsComponent';
import CareersComponent from './container/careers/careersComponent';
import PrivacyComponent from './container/privacy/privacyComponent';
import AffiliateComponent from './container/affiliate/affiliateComponent';


class RouterComponent extends Component {

    render() {
        return (

            <Router history={browserHistory}>
                <Route path="/" component={IndexComponent} />
                <Route path="/about-us" component={AboutComponent} />
                <Route path="/careers" component={CareersComponent} />
                <Route path="/privacy-policy" component={PrivacyComponent} />
                <Route path="/affiliate" component={AffiliateComponent} />
 
                <Route path="/dashboard" component={RootComponent} > 
                    <Route path="/dashboard" component={DashboardComponent} />
                </Route>
            </Router>
        );
    }
}

export default RouterComponent;