import React, { Component } from 'react'; 
import FooterComponent from '../../component/footer/menu/footerComponent';
import HowItWorksComponent from '../../component/index/howItWorks/howItWorksComponent';
import HowToGetStartedComponent from '../../component/index/howToGetStart/howToGetStartedComponent';
import PersonalAccountComponent from '../../component/index/personalAccount/personalAccountComponent';
import RatingComponent from '../../component/index/rating/ratingComponent';
import ReadyToGetStartedComponent from '../../component/index/readyToGetStarted/readyToGetStartedComponent';
import WelcomeComponent from '../../component/index/welcome/welcomeComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './style.scss'
import FeaturesOfTheBotComponent from '../../component/index/featuresOfTheBot/featuresOfTheBotComponent';
import HeaderComponent from '../../component/header/headerComponent';
import EarnSmarterComponent from '../../component/index/earnSmarter/earnSmarterComponent';
import InvestingCryptoComponent from '../../component/index/investingCrypto/investingCryptoComponent';

class IndexComponent extends Component {
    constructor(props) {
        super(props);
        AOS.init();
        this.state = {
            menuStatus: false
        }
    }
    render() {

        return (
            <div className="container-fluid p-0 m-0">
                  <HeaderComponent />
                 <WelcomeComponent />
                 <EarnSmarterComponent />
                 <InvestingCryptoComponent /> 
                 <FeaturesOfTheBotComponent />
                 <PersonalAccountComponent />
                 <HowToGetStartedComponent />
                 <RatingComponent />
                 <ReadyToGetStartedComponent />
                 <FooterComponent />
            </div>
        );
    }
}

export default IndexComponent;