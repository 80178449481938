import React, { Component } from 'react';

import './style.scss'

class RatingComponent extends Component {

    render() {

        return (
            <div className="container-fluid p-0 m-0 light-blue" >
               <div className="container p-5">
               <div className="rating-img"></div>
               </div>
            </div>
        ); 
    }
}

export default RatingComponent;