import React, { Component } from 'react';
import LogoComponent from '../logo/logoComponent';
import './style.scss'

class MenuComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
        }
    }
    menuTablet = React.createRef()

    openHamburger = (status) => {

        if (status === 'open')
            this.menuTablet.current.style.display = 'flex';
        else
            this.menuTablet.current.style.display = 'none';
    }

    render() {

        return (
            <div>
                <ul className="header-menu" >
                    <li>
                        <a href="http://accounts.bibitrade.com/register" className="header-menu-item " >
                            <p>Sign Up</p>
                        </a>
                    </li>
                    <li>
                        <a href="http://accounts.bibitrade.com/" className="header-menu-item  btn-login" >
                            <p>login</p>
                        </a>
                    </li>
                </ul>
                <div className="header-menu-humbugger" onClick={() => this.openHamburger('open')}>
                    <i className="fas fa-bars color-primary"></i>
                </div>

                <div className="menuTablet" ref={this.menuTablet}>
                    <div className="mobile-menu-container" >
                        <div className="m-header">
                            <div className="logo">
                                <LogoComponent />
                            </div>
                            <div className="m-close" onClick={(e) => this.openHamburger('close')} >
                                <i className="fas fa-times color-primary"></i>
                            </div>
                        </div>
                        <div className="m-body">
                            <div className="m-menu">
                                <ul onClick={() => this.openHamburger('close')}>
                                    <li>
                                        <a href="/about-us">Company</a>
                                    </li>
                                    <li>
                                        <a href="/affiliate">Affiliate</a>
                                    </li>
                                    <li>
                                        <a href="/careers">Careers</a>
                                    </li>
                                    <li>
                                        <a href="blog.bibitrade.com">Blog</a>
                                    </li> 
                                </ul>
                            </div>
                            <div className="m-footer" onClick={() => this.openHamburger('open')}>
                                <ul >
                                    <li>
                                        <a href="http://accounts.bibitrade.com/register" className="m-header-menu-item m-btn bg-primary" >
                                            <p>Sign Up</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://accounts.bibitrade.com/" className="m-header-menu-item m-btn " >
                                            <p>login</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuComponent;