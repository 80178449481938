import React, { Component } from 'react';
import './style.scss';

class EarnSmarterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid f8f8f8 pt-100">
                <div className="container pt-5">
                    <div className="row pt-5">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h1 className="color-primary text-bold">Earn Smarter</h1>
                            <h1 className="text-bold">Not Harder</h1>
                            <ul className="earn-ul">
                                <li>
                                    <i class="fas fa-check-circle color-primary"></i>
                                    <span>Get daily interest just for parking your assets.</span>
                                </li>
                                <li>
                                    <i class="fas fa-check-circle color-primary"></i>
                                    <span>Forget about fees for deposit or withdrawal.</span>
                                </li>
                                <li>
                                    <i class="fas fa-check-circle color-primary"></i>
                                    <span>Start in a minute and withdraw at any time.</span>
                                </li>
                            </ul>
                            <a href="http://accounts.bibitrade.com/register" className="btn btn-light" >
                                <p>Open Account Free</p>
                                <i className="fa fa-chevron-right"></i>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="earn-smarter-image">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default EarnSmarterComponent;