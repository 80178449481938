import React, { Component } from 'react';

import './style.scss'

class PersonalAccountComponent extends Component {

    render() {

        return (
            <div className="container-fluid h-700 personal-account" >
                <div className="container pt-5">
                    <div className="row pt-5">
                        <div className="col-lg-6 col-sm-12  pt-5">
                            <div className="mock-01"></div>
                        </div>
                        <div className="col-lg-6 col-sm-12  pt-5 flex-column d-flex justify-content-center">

                            <h1>Your Personal <br /> bibiTrade Account</h1>
                            <p className="pt-3 pb-3">Embrace your portfolio like the world's most sophisticated investors. We're making institutional portfolio management software available to everyone.</p>
                          
                            <a href="http://accounts.bibitrade.com/register" className="btn-sign-up" >
                                <p>Open Account Free</p>
                                <i className="fa fa-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        ); 
    }
}

export default PersonalAccountComponent;